<div class="header-initial" *ngIf="currentClientService.publicClientConfig$ | async; let client;">
  <div class="container">
    <div class="navbar-wrap">
      <div class="logo-wrapper">
        <a class="logo-link">
          <!-- <img id="header-logo" src="{{ client.logoImageURL }}" alt="Carrot Logo" class="logo"> -->  
          <div class="logo">
            <img id="header-logo" [src]="branding?.logoImageURL" [alt]="branding?.logoAltTag">
          </div>
          <span class="logo-text">{{ branding?.name }}</span>
        </a>
      </div>
      <div class="main-nav">
        <div class="nav-left">
          <!-- <div class="links-wrap">
            <a *ngIf="currentUserService.isLoggedIn$ | async" routerLink="{{ currentClientService.routePrefix }}/user/my-apps" [routerLinkActive]="['active']">
              <span>My Competitions</span>
            </a>
            <a *ngIf="currentUserService.isSsoAdmin$ | async" routerLink="{{ currentClientService.routePrefix }}/admin/user-management" [routerLinkActive]="['active']">
              <span>Users</span>
            </a>
            <a *ngIf="currentUserService.isSsoAdmin$ | async" routerLink="{{ currentClientService.routePrefix }}/admin/apps-management" [routerLinkActive]="['active']">
              <span>Apps Manager</span>
            </a>
            <a *ngIf="currentUserService.isSsoAdmin$ | async" routerLink="{{ currentClientService.routePrefix }}/admin/system-log" [routerLinkActive]="['active']">
              <span>System Log</span>
            </a>
          </div> -->
        </div>
        <div class="nav-right">
          <div class="links-wrap">
            <a *ngIf="currentUserService.isLoggedIn$ | async" routerLink="{{ currentClientService.routePrefix }}/user/my-apps" [routerLinkActive]="['active']">
              <span>My Competitions</span>
            </a>
          </div>
          <div class="divider"></div>
          <!-- profile menu -->
          <div class="user-dropdown" (clickOutside)="closeProfileMenu()" *ngIf="currentUserService.isLoggedIn$ | async">
            <!-- profile menu icon / dropdown trigger -->
            <button class="user-dropdown-trigger" (click)="toggleProfileMenu()">
              <mat-icon [ngClass]="{'userInfoMenuSelected': showProfileMenu}">account_circle</mat-icon>
            </button>
            <!-- end profile menu icon / dropdown trigger -->
            <!-- dropdown menu wrapper -->
            <div class="dropdown-menu avatar_dropdown" [ngClass]="{'show-profile': showProfileMenu}">
              <!-- avatar wrapper -->
              <div class="dropdown-avatar">
                <!-- avatar icon -->
                <div class="user-icon__block">
                  <mat-icon  aria-hidden="false" >account_circle</mat-icon>
                </div>
                <!-- end avatar icon -->
                <!-- user info -->
                <div class="text_block">
                  <span class="dropdown-avatar_text" *ngIf="user">{{ user.firstname }} {{ user.lastname }}</span>
                  <span class="dropdown-avatar_email" *ngIf="user">{{ user.email }}</span>
                </div>
                <!-- end user info -->
              </div>
              <!-- end avatar wrapper -->
              <!-- account settings link -->
              <div class="main_item">
                <ng-container *ngIf="currentUserService.isLoggedIn$ | async">
                  <a routerLink="{{ currentClientService.routePrefix }}/user/account-settings" (click)="closeProfileMenu()" [routerLinkActive]="['active']">
                    <mat-icon  aria-hidden="false">settings</mat-icon><strong>Account Settings</strong>
                  </a>
                  <a routerLink="{{ currentClientService.routePrefix }}/user/notification-settings" (click)="closeProfileMenu()" [routerLinkActive]="['active']">
                    <mat-icon  aria-hidden="false">notifications</mat-icon><strong>Notification Settings</strong>
                  </a>
                </ng-container>
              </div>
              <!-- end account settings link -->
              <!-- logout button -->
              <div class="main_item">
                <a *ngIf="currentUserService.isLoggedIn$ | async" (click)="logout()">
                  <mat-icon>logout</mat-icon>
                  <strong>Logout</strong>
                </a>
              </div>
              <!-- end logout button -->
            </div>
            <!-- end dropdown menu wrapper -->
          </div>
          <!-- end profile menu -->
          <div class="nav-menu" (clickOutside)="closeNavMenu()" *ngIf="user?.isSsoAdmin">
            <button class="trigger" (click)="toggleNavMenu()" [class.active]="navMenu.open">
              <svg xmlns="http://www.w3.org/2000/svg" width="10" height="2" viewBox="0 0 10 2">
                <path d="M1 1L9 1" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="2" viewBox="0 0 18 2">
                <path d="M1 1H17" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="10" height="2" viewBox="0 0 10 2">
                <path d="M1 1L9 1" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
              </svg>
            </button>
            <div class="menu" [class.open]="navMenu.open">
              <ul>
                <li *ngFor="let item of navMenu.items" routerLinkActive="active">
                  <a *ngIf="!item.expandable" [routerLink]="item.link" (click)="closeNavMenu()" routerLinkActive="active">
                    <mat-icon>{{ item.icon }}</mat-icon>
                    {{ item.label }}
                  </a>
                  <ng-container *ngIf="item.expandable">
                    <a (click)="item.expanded = !item.expanded" [class.active]="item.expanded">
                      <mat-icon>{{ item.icon }}</mat-icon>
                      {{ item.label }}
                      <mat-icon class="arrow">{{ item.expanded ? "keyboard_arrow_up" : "keyboard_arrow_down" }}</mat-icon>
                    </a>
                    <ul [class.expanded]="item.expanded">
                      <li *ngFor="let innerItem of item.items">
                        <a [routerLink]="innerItem.link" (click)="closeNavMenu()" routerLinkActive="active">
                          {{ innerItem.label }}
                        </a>
                      </li>
                    </ul>
                  </ng-container>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
